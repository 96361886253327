<template>
    <div>{{model.name}}</div>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
    props: {
        object: {type: Object, require: true}
    },
    data() {
        return {
            
        }
    },
    computed: {
        ...mapGetters({
            models: 'host/models',
            versions: 'host/versions'
        }),
        version(){
            return this.versions.find(item => this.object.version === item.uid)
        },
        model(){
            return this.models.find(item => this.version.model === item.uid)
        }
    }
}
</script>